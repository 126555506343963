/**
 * Uniform Theme: Uniform Default
 *
 * @author Josh Pyles
 */

$button-height: 30px;
$button-margin-left: 13px;
$button-padding: 0 15px 0 2px;
$checkbox-height: 19px;
$checkbox-width: 19px;
$input-padding: 3px;
$radio-height: 18px;
$radio-width: 18px;
$select-fixed-width: 190px;
$select-height: 26px;
$select-margin-left: 10px;
$select-margin-right: 25px;
$select-select-height: 22px;
$select-select-top: 2px;
$upload-action-width: 82px;
$upload-filename-margin-top: 2px;
$upload-filename-margin-bottom: 2px;
$upload-filename-margin-left: 2px;
$upload-filename-width: 85px;
$upload-filename-padding: 0 10px;
$upload-height: 28px;
$upload-width: 190px;

@import "_base.scss";

/* INPUT & TEXTAREA */

#{$class-wrapper-element}#{$class-wrapper} input#{$class-input},
#{$class-wrapper-element}#{$class-wrapper} select#{$class-multiselect},
#{$class-wrapper-element}#{$class-wrapper} textarea#{$class-textarea} {
    font-size: 12px;
    font-family: "Helvetica Neue", Arial, Helvetica, sans-serif;
    font-weight: normal;
    color: #777777;
    border: 1px solid #aaaaaa;
    border-right-color: #cccccc;
    border-bottom-color: #cccccc;
    border-radius: $input-padding;
    background-color: white;

    @include whenHover {
        box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
        border-color: #999999;
    }

    @include whenActive {
        background-color: white;
    }
}

/* PRESENTATION */

/* Buttons */

div#{$class-wrapper}#{$class-button} {
    span {
        font-weight: bold;
        font-family: "Helvetica Neue", Arial, Helvetica, sans-serif;
        font-size: 12px;
        letter-spacing: 1px;
        text-transform: uppercase;
    }

    @include whenHover {
        span {
            color: #555555;
        }
    }

    @include whenDisabled {
        span {
            color: #bbbbbb;
        }
    }
}

/* Select */

div#{$class-wrapper}#{$class-select} {
    font-size: 12px;

    span {
        color: #666666;
        text-shadow: 0 1px 0 #ffffff;
    }

    select {
        font-family: "Helvetica Neue", Arial, Helvetica, sans-serif;
        font-size: 12px;
    }

    @include whenDisabled {
        span {
            color: #bbbbbb;
        }
    }
}

/* Checker */
div#{$class-wrapper}#{$class-checkbox} {
    margin-right: 5px;
}

/* Radio */
div#{$class-wrapper}#{$class-radio} {
    margin-right: 3px;
}

/* Uploader */
div#{$class-wrapper}#{$class-upload} {
    span#{$class-action} {
        text-shadow: #ffffff 0 1px 0;
        background-color: #ffffff;
        font-size: 11px;
        font-weight: bold;
    }

    span#{$class-filename} {
        color: #777777;
        border-right: solid 1px #bbbbbb;
        font-size: 11px;
    }

    @include whenDisabled {
        span#{$class-action} {
            color: #aaaaaa;
        }

        span#{$class-filename} {
            border-color: #dddddd;
            color: #aaaaaa;
        }
    }
}

#{$class-wrapper-element}#{$class-wrapper} input#{$class-input} {
    &, &:focus {
        background-color: #ffffff;
    }
}
