/*input fields label styles*/
.control-label {
  margin-top: 1px;
  font-weight: 600; }

@media (min-width: 768px) and (max-width: 1400px) {
  .multiline_label {
    margin-top: -7px !important; } }

/*/*label in mulltiple lines */
@media (min-width: 970px) and (max-width: 1400px) {
  .triple_line_label {
    margin-top: -16px; } }

.checkbox input[type=checkbox],
.checkbox-inline input[type=checkbox],
.radio input[type=radio],
.radio-inline input[type=radio] {
  /* margin-left: -9px; */
  /*margin-left: -20px;*/ }

/*Tabs count badges*/
.badge {
  font-size: 12px !important;
  font-weight: 600; }

.badge-primary {
  background-color: #69a0d2; }

pills > li > a > .badge, .nav-tabs > li > a > .badge {
  margin-top: -4px; }

/*end badges*/
/*Tabs NUMBERING IN MODE*/
/*padding-right: 10px;*/
/*padding-left: 10px;*/
.crm-tab-nav > li > a span {
  top: 0px;
  padding-right: 0px;
  padding-left: 5px;
  color: #c6d1e5;
  font-size: 11px;
  /*position: absolute;*/
  text-align: center; }

.crm-tab-nav > li > a span:before {
  content: "("; }

.crm-tab-nav > li > a span:after {
  content: ")"; }

/*when tabs hidden in drop*/
.dropdown-menu > li > a span:before {
  content: "("; }

.dropdown-menu > li > a span:after {
  content: ")"; }

.dropdown-menu > li > a span {
  right: 3px;
  color: #c6d1e5;
  font-size: 11px;
  position: absolute;
  text-align: center; }

/*notes*/
.timeline .timeline-body-time {
  font-size: 12px; }

.timeline .timeline-body-content {
  font-size: 13px; }

.page-head .page-title > h1 {
  font-size: 20px; }

h3 {
  font-size: 20px; }

h2 {
  font-size: 24px; }

h1 {
  font-size: 22px; }

.portlet > .portlet-title > .caption {
  font-size: 16px; }

.timeline .timeline-body-alerttitle, .timeline .timeline-body-title {
  font-size: 14px; }

/*pharentheses in drop*/
/*END NUMBERING*/


/*for label required field*/
label.label-required:after {
    color: #cc0000;
    content: "*";
    font-weight: bold;
    margin-left: 5px;
}

.label {
  font-size:12px;
}
/*sidebar  */
.page-quick-sidebar-wrapper {
    width: 450px;
    right: -450px;
    color: #34495e;
    overflow: none;
    z-index: 12000;
}

.divider{
  border-top: 1px solid rgb(209, 209, 209);
}

#quick-view-trigger{
  cursor: pointer;
  font-size: 14px;
}
.datepicker{
  z-index: 9999;
}

[class*=" glyphicon-"] {
    line-height: 12px !important;
}


/* vuetable Loading Animation: https://github.com/ratiw/vue-table */ 
.vuetable-wrapper {
    opacity: 1;
    position: relative;
    filter: alpha(opacity=100); /* IE8 and earlier */
}
.vuetable-wrapper.loading {
  opacity:0.4;
   transition: opacity .3s ease-in-out;
   -moz-transition: opacity .3s ease-in-out;
   -webkit-transition: opacity .3s ease-in-out;
}
.vuetable-wrapper.loading:after {
  position: absolute;
  content: '';
  top: 40%;
  left: 50%;
  margin: -30px 0 0 -30px;
  border-radius: 100%;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  border: 4px solid #000;
  height: 60px;
  width: 60px;
  background: transparent !important;
  display: inline-block;
  -webkit-animation: pulse 1s 0s ease-in-out infinite;
          animation: pulse 1s 0s ease-in-out infinite;
}
@keyframes pulse {
  0% {
    -webkit-transform: scale(0.6);
            transform: scale(0.6); }
  50% {
    -webkit-transform: scale(1);
            transform: scale(1);
         border-width: 12px; }
  100% {
    -webkit-transform: scale(0.6);
            transform: scale(0.6); }
}

/* Nice alerts and confirms https://alertifyjs.org/ */
.alertify {
    z-index: 9999 !important;
}


.page-header.navbar .top-menu .navbar-nav>li.dropdown-extended .dropdown-menu {
  min-width: 350px;
  max-width: 350px;
  width: 350px;
  z-index: 9995;
}
