/* Cubic Bezier Transition */
/***********
    Theme Colors
    ***********/
.theme-font-color {
  color: #5b9bd1; }

/***********
    Body bg color
    ***********/
body {
  background: #e9ecf3; }
  body.page-md {
    background: #f3f5f9; }

/*********** 
    Page Header
    ***********/
/* Header search bar, toggler button & top menu */
.page-header.navbar {
  background: #FFFFFF;
  border-bottom: 1px solid #e9ecf3;
  /* Top notification menu/bar */
  /* Header seaech box */
  /* Menu Toggler */ }
  .page-header.navbar .top-menu .navbar-nav {
    /* Extended Dropdowns */
    /* Notification */
    /* Inbox */
    /* Tasks */
    /* User */
    /* Language */
    /* Dark version */ }
    .page-header.navbar .top-menu .navbar-nav > li.dropdown .dropdown-toggle:hover {
      background-color: #f9fafc; }
    .page-header.navbar .top-menu .navbar-nav > li.dropdown .dropdown-toggle > i {
      color: #C0CDDC; }
    .page-header.navbar .top-menu .navbar-nav > li.dropdown .dropdown-toggle .badge.badge-default {
      background-color: #5b9bd1;
      color: #ffffff; }
    .page-header.navbar .top-menu .navbar-nav > li.dropdown.open .dropdown-toggle {
      background-color: #f9fafc; }
    .page-header.navbar .top-menu .navbar-nav > li.dropdown .dropdown-menu:before {
      border-bottom-color: #dce1ec; }
    .page-header.navbar .top-menu .navbar-nav > li.separator {
      background: #e9ecef; }
    .page-header.navbar .top-menu .navbar-nav > li.dropdown-extended .dropdown-menu {
      border: 1px solid #dce1ec; }
      .page-header.navbar .top-menu .navbar-nav > li.dropdown-extended .dropdown-menu:before {
        border-bottom-color: #cbd2e3; }
      .page-header.navbar .top-menu .navbar-nav > li.dropdown-extended .dropdown-menu:after {
        border-bottom-color: #e3e6f0; }
      .page-header.navbar .top-menu .navbar-nav > li.dropdown-extended .dropdown-menu > li.external {
        background: #e3e6f0; }
        .page-header.navbar .top-menu .navbar-nav > li.dropdown-extended .dropdown-menu > li.external > h3 {
          color: #62878f; }
        .page-header.navbar .top-menu .navbar-nav > li.dropdown-extended .dropdown-menu > li.external > a {
          color: #337ab7; }
          .page-header.navbar .top-menu .navbar-nav > li.dropdown-extended .dropdown-menu > li.external > a:hover {
            color: #23527c;
            text-decoration: none; }
      .page-header.navbar .top-menu .navbar-nav > li.dropdown-extended .dropdown-menu .dropdown-menu-list > li > a {
        border-bottom: 1px solid #EFF2F6 !important;
        color: #888888; }
        .page-header.navbar .top-menu .navbar-nav > li.dropdown-extended .dropdown-menu .dropdown-menu-list > li > a:hover {
          background: #f8f9fa; }
    .page-header.navbar .top-menu .navbar-nav > li.dropdown-notification .dropdown-menu .dropdown-menu-list > li > a .time {
      background: #f1f1f1; }
    .page-header.navbar .top-menu .navbar-nav > li.dropdown-notification .dropdown-menu .dropdown-menu-list > li > a:hover .time {
      background: #e4e4e4; }
    .page-header.navbar .top-menu .navbar-nav > li.dropdown-inbox > .dropdown-toggle > .circle {
      background-color: #5b9bd1;
      color: #ffffff; }
    .page-header.navbar .top-menu .navbar-nav > li.dropdown-inbox > .dropdown-toggle > .corner {
      border-color: transparent transparent transparent #5b9bd1; }
    .page-header.navbar .top-menu .navbar-nav > li.dropdown-inbox .dropdown-menu .dropdown-menu-list .subject .from {
      color: #5b9bd1; }
    .page-header.navbar .top-menu .navbar-nav > li.dropdown-tasks .dropdown-menu .dropdown-menu-list .progress {
      background-color: #dfe2e9; }
    .page-header.navbar .top-menu .navbar-nav > li.dropdown-user > .dropdown-toggle > .username {
      color: #7FB0DA; }
    .page-header.navbar .top-menu .navbar-nav > li.dropdown-user > .dropdown-toggle > i {
      color: #7FB0DA; }
    .page-header.navbar .top-menu .navbar-nav > li.dropdown-user > .dropdown-menu {
      width: 195px; }
    .page-header.navbar .top-menu .navbar-nav > li.dropdown-language > .dropdown-toggle > .langname {
      color: #7FB0DA; }
    .page-header.navbar .top-menu .navbar-nav > li.dropdown-dark .dropdown-menu {
      background: #536470;
      border: 0; }
      .page-header.navbar .top-menu .navbar-nav > li.dropdown-dark .dropdown-menu:after {
        border-bottom-color: #536470; }
      .page-header.navbar .top-menu .navbar-nav > li.dropdown-dark .dropdown-menu > li.external {
        background: #414f59; }
        .page-header.navbar .top-menu .navbar-nav > li.dropdown-dark .dropdown-menu > li.external > h3 {
          color: #c5ced4; }
        .page-header.navbar .top-menu .navbar-nav > li.dropdown-dark .dropdown-menu > li.external > a:hover {
          color: #5496cf; }
      .page-header.navbar .top-menu .navbar-nav > li.dropdown-dark .dropdown-menu.dropdown-menu-default > li a,
      .page-header.navbar .top-menu .navbar-nav > li.dropdown-dark .dropdown-menu .dropdown-menu-list > li a {
        color: #d4dadf;
        border-bottom: 1px solid #607482 !important; }
        .page-header.navbar .top-menu .navbar-nav > li.dropdown-dark .dropdown-menu.dropdown-menu-default > li a > i,
        .page-header.navbar .top-menu .navbar-nav > li.dropdown-dark .dropdown-menu .dropdown-menu-list > li a > i {
          color: #b6c2c9; }
        .page-header.navbar .top-menu .navbar-nav > li.dropdown-dark .dropdown-menu.dropdown-menu-default > li a:hover,
        .page-header.navbar .top-menu .navbar-nav > li.dropdown-dark .dropdown-menu .dropdown-menu-list > li a:hover {
          background: #5b6f7c; }
      .page-header.navbar .top-menu .navbar-nav > li.dropdown-dark .dropdown-menu.dropdown-menu-default > li a {
        border-bottom: 0 !important; }
      .page-header.navbar .top-menu .navbar-nav > li.dropdown-dark .dropdown-menu.dropdown-menu-default > li.divider {
        background: #607482; }
    .page-header.navbar .top-menu .navbar-nav > li.dropdown-notification.dropdown-dark .dropdown-menu .dropdown-menu-list > li > a .time {
      background: #485762; }
    .page-header.navbar .top-menu .navbar-nav > li.dropdown-notification.dropdown-dark .dropdown-menu .dropdown-menu-list > li > a:hover .time {
      background: #3d4a53; }
  .page-header.navbar .search-form {
    background: #FFFFFF; }
    .page-header.navbar .search-form .input-group {
      border: 1px solid #ced6de; }
      .page-header.navbar .search-form .input-group:hover {
        border-color: #bfcad4; }
      .page-header.navbar .search-form .input-group .form-control {
        color: #ced6de; }
        .page-header.navbar .search-form .input-group .form-control::-moz-placeholder {
          color: #cbd4dc;
          opacity: 1; }
        .page-header.navbar .search-form .input-group .form-control:-ms-input-placeholder {
          color: #cbd4dc; }
        .page-header.navbar .search-form .input-group .form-control::-webkit-input-placeholder {
          color: #cbd4dc; }
      .page-header.navbar .search-form .input-group .input-group-btn .btn.submit > i {
        color: #ced6de; }
  .page-header.navbar .menu-toggler {
    background-image: url(../../img/sidebar-toggle-light.png); }
  .page-header.navbar .page-actions .btn-group .btn-sm.btn-transparent {
    background: none;
    border: 1px solid white;
    color: white; }
    .page-header.navbar .page-actions .btn-group .btn-sm.btn-transparent > i {
      color: white; }
    .page-header.navbar .page-actions .btn-group .btn-sm.btn-transparent:hover,
    .page-header.navbar .page-actions .btn-group .btn-sm.btn-transparent:focus,
    .page-header.navbar .page-actions .btn-group .btn-sm.btn-transparent.active {
      background: none;
      border: 1px solid white;
      color: white; }
      .page-header.navbar .page-actions .btn-group .btn-sm.btn-transparent:hover > i,
      .page-header.navbar .page-actions .btn-group .btn-sm.btn-transparent:focus > i,
      .page-header.navbar .page-actions .btn-group .btn-sm.btn-transparent.active > i {
        color: white; }

/* Page sidebar */
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover,
.page-sidebar {
  background-color: #ffffff;
  /* Default sidebar */ }
  .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu,
  .page-sidebar .page-sidebar-menu {
    /* 1st level links */
    /* All links */ }
    .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.heading > h3,
    .page-sidebar .page-sidebar-menu > li.heading > h3 {
      color: #5C9ACF; }
    .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li > a,
    .page-sidebar .page-sidebar-menu > li > a {
      color: #485a6a; }
      @media (max-width: 991px) {
        .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li > a,
        .page-sidebar .page-sidebar-menu > li > a {
          /* 991px */
          border-top: 1px solid white; } }
      .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li > a > i,
      .page-sidebar .page-sidebar-menu > li > a > i {
        color: #97b1c3; }
      .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li > a > i[class^="icon-"],
      .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li > a > i[class*="icon-"],
      .page-sidebar .page-sidebar-menu > li > a > i[class^="icon-"],
      .page-sidebar .page-sidebar-menu > li > a > i[class*="icon-"] {
        color: #a7bdcd; }
      .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li > a > .arrow:before,
      .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li > a > .arrow.open:before,
      .page-sidebar .page-sidebar-menu > li > a > .arrow:before,
      .page-sidebar .page-sidebar-menu > li > a > .arrow.open:before {
        color: #a0b8c9; }
    .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li:hover > a,
    .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.open > a,
    .page-sidebar .page-sidebar-menu > li:hover > a,
    .page-sidebar .page-sidebar-menu > li.open > a {
      background: #f2f6f9;
      color: #5b9bd1; }
      .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li:hover > a > i,
      .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.open > a > i,
      .page-sidebar .page-sidebar-menu > li:hover > a > i,
      .page-sidebar .page-sidebar-menu > li.open > a > i {
        color: #5b9bd1; }
      .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li:hover > a > .arrow:before,
      .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li:hover > a > .arrow.open:before,
      .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.open > a > .arrow:before,
      .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.open > a > .arrow.open:before,
      .page-sidebar .page-sidebar-menu > li:hover > a > .arrow:before,
      .page-sidebar .page-sidebar-menu > li:hover > a > .arrow.open:before,
      .page-sidebar .page-sidebar-menu > li.open > a > .arrow:before,
      .page-sidebar .page-sidebar-menu > li.open > a > .arrow.open:before {
        color: #67a2d4; }
    .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.active > a,
    .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.active.open > a,
    .page-sidebar .page-sidebar-menu > li.active > a,
    .page-sidebar .page-sidebar-menu > li.active.open > a {
      background: #f2f6f9;
      color: #5b9bd1; }
      .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.active > a:hover,
      .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.active.open > a:hover,
      .page-sidebar .page-sidebar-menu > li.active > a:hover,
      .page-sidebar .page-sidebar-menu > li.active.open > a:hover {
        background: #f2f6f9; }
      .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.active > a > i,
      .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.active.open > a > i,
      .page-sidebar .page-sidebar-menu > li.active > a > i,
      .page-sidebar .page-sidebar-menu > li.active.open > a > i {
        color: #5b9bd1; }
      .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.active > a > .arrow:before,
      .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.active > a > .arrow.open:before,
      .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.active.open > a > .arrow:before,
      .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.active.open > a > .arrow.open:before,
      .page-sidebar .page-sidebar-menu > li.active > a > .arrow:before,
      .page-sidebar .page-sidebar-menu > li.active > a > .arrow.open:before,
      .page-sidebar .page-sidebar-menu > li.active.open > a > .arrow:before,
      .page-sidebar .page-sidebar-menu > li.active.open > a > .arrow.open:before {
        color: #67a2d4; }
    .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.active > a,
    .page-sidebar .page-sidebar-menu > li.active > a {
      border-left: 3px solid #5C9ACF !important;
      margin-left: -3px;
      padding-left: 15px; }
    .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu li > a > .arrow:before,
    .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu li > a > .arrow.open:before,
    .page-sidebar .page-sidebar-menu li > a > .arrow:before,
    .page-sidebar .page-sidebar-menu li > a > .arrow.open:before {
      color: #a0b8c9; }
    .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu li:hover > a > .arrow:before,
    .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu li:hover > a > .arrow.open:before,
    .page-sidebar .page-sidebar-menu li:hover > a > .arrow:before,
    .page-sidebar .page-sidebar-menu li:hover > a > .arrow.open:before {
      color: #67a2d4; }
    .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu li.active > a > .arrow:before,
    .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu li.active > a > .arrow.open:before,
    .page-sidebar .page-sidebar-menu li.active > a > .arrow:before,
    .page-sidebar .page-sidebar-menu li.active > a > .arrow.open:before {
      color: #67a2d4; }
    .page-sidebar-closed .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu:hover .sub-menu,
    .page-sidebar-closed .page-sidebar .page-sidebar-menu:hover .sub-menu {
      background: #FFFFFF; }
    .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu > li > a,
    .page-sidebar .page-sidebar-menu .sub-menu > li > a {
      color: #485a6a; }
      .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu > li > a > i,
      .page-sidebar .page-sidebar-menu .sub-menu > li > a > i {
        color: #97b1c3; }
      .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu > li > a > i[class^="icon-"],
      .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu > li > a > i[class*="icon-"],
      .page-sidebar .page-sidebar-menu .sub-menu > li > a > i[class^="icon-"],
      .page-sidebar .page-sidebar-menu .sub-menu > li > a > i[class*="icon-"] {
        color: #a7bdcd; }
      .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu > li > a > .arrow:before,
      .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu > li > a > .arrow.open:before,
      .page-sidebar .page-sidebar-menu .sub-menu > li > a > .arrow:before,
      .page-sidebar .page-sidebar-menu .sub-menu > li > a > .arrow.open:before {
        color: #a0b8c9; }
    .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu > li:hover > a,
    .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu > li.open > a,
    .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu > li.active > a,
    .page-sidebar .page-sidebar-menu .sub-menu > li:hover > a,
    .page-sidebar .page-sidebar-menu .sub-menu > li.open > a,
    .page-sidebar .page-sidebar-menu .sub-menu > li.active > a {
      color: #5b9bd1;
      background: #f2f6f9 !important; }
      .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu > li:hover > a > i,
      .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu > li.open > a > i,
      .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu > li.active > a > i,
      .page-sidebar .page-sidebar-menu .sub-menu > li:hover > a > i,
      .page-sidebar .page-sidebar-menu .sub-menu > li.open > a > i,
      .page-sidebar .page-sidebar-menu .sub-menu > li.active > a > i {
        color: #5b9bd1; }
      .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu > li:hover > a > .arrow:before,
      .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu > li:hover > a > .arrow.open:before,
      .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu > li.open > a > .arrow:before,
      .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu > li.open > a > .arrow.open:before,
      .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu > li.active > a > .arrow:before,
      .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu > li.active > a > .arrow.open:before,
      .page-sidebar .page-sidebar-menu .sub-menu > li:hover > a > .arrow:before,
      .page-sidebar .page-sidebar-menu .sub-menu > li:hover > a > .arrow.open:before,
      .page-sidebar .page-sidebar-menu .sub-menu > li.open > a > .arrow:before,
      .page-sidebar .page-sidebar-menu .sub-menu > li.open > a > .arrow.open:before,
      .page-sidebar .page-sidebar-menu .sub-menu > li.active > a > .arrow:before,
      .page-sidebar .page-sidebar-menu .sub-menu > li.active > a > .arrow.open:before {
        color: #67a2d4; }

/******
    Page Footer 
    ******/
.page-footer .page-footer-inner {
  color: #33404c; }

.page-footer-fixed .page-footer {
  background-color: #e9ecf3; }
  .page-footer-fixed .page-footer .page-footer-inner {
    color: black; }

.page-boxed .page-footer .page-footer-inner {
  color: black; }

.page-footer-fixed .page-boxed .page-footer {
  background-color: #e9ecf3; }

@media (min-width: 992px) {
  /* 992px */
  /* Sidebar menu closed */
  .page-sidebar-menu.page-sidebar-menu-hover-submenu li:hover > .sub-menu {
    box-shadow: 5px 5px rgba(140, 178, 198, 0.2); }
  .page-sidebar-menu.page-sidebar-menu-closed > li:hover {
    box-shadow: 5px 5px rgba(140, 178, 198, 0.2); }
    .page-sidebar-menu.page-sidebar-menu-closed > li:hover > .sub-menu {
      box-shadow: 5px 5px rgba(140, 178, 198, 0.2); }
  /* Fixed Sidebar */
  .page-sidebar-fixed:not(.page-footer-fixed) .page-content {
    border-bottom: 0; }
  .page-sidebar-fixed:not(.page-footer-fixed) .page-footer .page-footer-inner {
    color: #333; }
  /* Boxed Layout */
  .page-boxed {
    /* Page container */ }
    .page-boxed .page-container {
      background-color: #e9ecf3; }
    .page-boxed.page-sidebar-reversed .page-container {
      border-left: 0; }
    .page-boxed.page-sidebar-fixed .page-container {
      border-left: 0;
      border-bottom: 0; }
    .page-boxed.page-sidebar-reversed.page-sidebar-fixed .page-container {
      border-left: 0;
      border-right: 0;
      border-bottom: 0; }
  /* Sidebar Menu Wirh Hoverable Submenu */
  .page-sidebar-menu-hover-submenu li:hover a > .arrow {
    border-right: 8px solid #FFFFFF; }
    .page-sidebar-reversed .page-sidebar-menu-hover-submenu li:hover a > .arrow {
      border-left: 8px solid #FFFFFF; }
  .page-sidebar-menu-hover-submenu li:hover > .sub-menu {
    background: #FFFFFF !important; } }

@media (max-width: 991px) {
  /* 991px */
  .page-header.navbar {
    padding: 0;
    /* Top menu */ }
    .page-header.navbar .top-menu .navbar-nav > li.dropdown > .dropdown-toggle:hover {
      background-color: #f6f7fa; } }

@media (max-width: 767px) {
  /* 767px */
  .page-header.navbar {
    background: #FFFFFF;
    border-bottom: 1px solid white;
    /* Top menu */ }
    .page-header.navbar .page-top {
      background: #FFFFFF; }
    .page-header.navbar .top-menu .navbar-nav > li.dropdown > .dropdown-toggle:hover {
      background-color: #eff1f6; } }

/****
 CSS3 Spinner Bar  
****/
.page-spinner-bar > div,
.block-spinner-bar > div {
  background: #6fa7d7; }
